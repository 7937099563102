import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Appbar.css';
import logo from '../images/abg-logo.jpg'
import whatsapp from '../images/whatsapp.png'

function DrawerAppBar() {
  const [click, setClick] = useState(false);

  const handleClick = () =>setClick(!click);
  const closeMobileMenu = () =>setClick(false);
  return (
    <>
    <app className="appbar" display="fixed" >
      <div className="appbar-container" >
        <div className='app-logo'>
          <img src={logo} alt="Logo" style={{width:'150px', height:"80px", maxWidth:'300px',minWidth:'150px'}}></img>
        {/* <Link to="/" className='appbar-logo'  style={{letterSpacing:"2px"}}>
          ABG
        </Link> */}
        </div>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='app-item'>
            <Link to='/' className='app-item' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className='app-item'>
            <Link to='/aboutus' className='app-item' onClick={closeMobileMenu}>
              About
            </Link>
          </li>
          <li className='app-item'>
            <Link to='/services' className='app-item' onClick={closeMobileMenu}>
            Services
            </Link>
          </li>
          <li className='app-item'>
            <Link to='/contactus' className='app-item' onClick={closeMobileMenu}>
              Contact Us
            </Link>
          </li>    
          <li>
          <a href="https://api.whatsapp.com/send/?phone=919302376232&text&type=phone_number&app_absent=0" 
          target="_blank" rel="noreferrer" > <img src={whatsapp} alt="whatsapp" width="45px" height="auto"/></a>
          </li>
        </ul>
      </div>
    </app>
    </>
  )
}

export default DrawerAppBar
