import React from "react";
import {Routes, Route} from 'react-router-dom';
import DrawerAppBar from "./components/DrawerAppBar";
import Home from "./components/Home";
import Aboutus from "./components/Aboutus"
import Contact from "./components/Contact"
import Services from "./components/Services";
import Footer from "./components/Footer";


function App() {
  return (
    <div className="App">
      <DrawerAppBar/>
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/aboutus" element={<Aboutus/>}></Route>
        <Route path="/services" element={<Services/>}></Route>
        <Route path="/contactus" element={<Contact/>}></Route>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
