import React from "react";
import "./Contact.css";
import contact from "../images/contact3.jpg";


export default function Contact() {
    const containerStyle = {
        backgroundImage: `url(${contact})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat", 
        padding:"100px"  
      };
      
  return (
        <div  style={containerStyle}>
          <h2 className="text-white" style={{textAlign:"center"}}>Feel free to contact us !</h2>
           <div className="contact">
            <form
              className="contact-form"
              action="https://formspree.io/f/xnnadgnz"
              // action="https://formsubmit.co/vinayssukad@gmail.com"
              method="POST"
            >
              <input
                type="text"
                name="Name"
                placeholder="Name"
                autoComplete="off"
                required
              />
              <input
                type="email"
                name="Email"
                placeholder="Email"
                autoComplete="off"
                required
              />
              <input
                type="number"
                name="Number"
                placeholder="Contact Number"
                autoComplete="off"
                required 
              />
              {/* <input type='text' name='username' placeholder='username' autoComplete='off' required/> */}
              <textarea
                name="Message"
                placeholder="Purpose Of Contact"
                cols="30"
                rows="5"
                autoComplete="off"
                required
              />
              <input type="submit" value="Send" className="submit-button" />
            </form>
          </div>
        </div>
     
   
  );
}
