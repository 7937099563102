import React from 'react'

export default function Footer() {
  return (
    <div className='text-center '>
      <div className='  d-md-flex p-3' style={{justifyContent:'space-between',backgroundColor:"black"}}>

      <div className="text-center text-white">
        <h4><i class="bi bi-person-rolodex h4" style={{color:"#1b7dff"}}></i> Contact</h4>
        <p className='text-center'> <i class="bi bi-envelope h6" ></i><a className='text-white text-decoration-none h6' href="mailto:support@abghr.com"> support@abghr.com</a> <br/> <i class="bi bi-phone h6"></i>+91-9302376232</p>
      </div>

      <div className='text-white text-center'>
        <h4><i class="bi bi-geo-alt h4" style={{color:"#1b7dff"}}></i>Address</h4>
        <p>No. 21,2nd Cross, University Post, R R Layout, 80 Feet Rd, <br/>Mutharayana Nagar,Kengeri Satellite Town,<br/> Bengaluru, Karnataka 560056</p>
      </div>

      <div  style={{padding:"10px"}}>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15927600.817891844!2d57.979098749999984!3d12.935052100000009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3f00349217d7%3A0x5e99c2d3c8544898!2sABG%20Consultants!5e0!3m2!1sen!2sin!4v1721457059511!5m2!1sen!2sin" 
      width="auto" height="150" style={{borderRadius:"10px"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='office_place'></iframe>
      
       </div>


     </div>
    </div>
  )
}
